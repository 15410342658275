import React, { useState, useEffect, CSSProperties } from 'react';
import { getCompanies, getTickerSock } from '../apicalls/apicalls';
import Company from '../CompanyItem/CompanyItem';
import {setCompanies} from '../../../../store/DalalbullReducer/Dalalbull.Actions';
import { selectCompanies } from '../../../../store/DalalbullReducer/Dalalbull.Selectors';
import { useDispatch, useSelector } from 'react-redux';
import './ListCompanies.scss';
import Fuse from 'fuse.js';
import { ClipLoader } from 'react-spinners';

const allCompany = (companies:any) => {
  return companies?.map((company:any) => (
    <Company {...company} key={company['symbol']} />
  ));
};

const ListCompanies = () => {
  const dispatch = useDispatch();
  const companies = useSelector(selectCompanies);
  const [searchTerm, setSearchTerm] = useState('');
  const [result, setResult] = useState<typeof companies>([]);

  useEffect(() => {
    getCompanies().then(res => {
      dispatch(setCompanies(res['tickerData']));
      setResult(res['tickerData']);
    })
  }, [dispatch]);

  useEffect(() => {
    const tickSock = getTickerSock(); 
    const onMessage = e => {
      const data = JSON.parse(e.data);
      if (!data.hasOwnProperty('msg')) {
        const tickerData = JSON.parse(data.data).tickerData;
        dispatch(setCompanies(tickerData)); 
        setResult(tickerData);
      }
    };
  
    tickSock.addEventListener('message', onMessage);
  
    return () => {
      tickSock.removeEventListener('message', onMessage);
      tickSock.close(); 
    };
  }, []);

  const search = () => {
    const options = {
      shouldSort: true,
      threshold: 0.6,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: ['name', 'symbol'],
    };
    const fuse = new Fuse(companies, options);
    setResult(fuse.search(searchTerm).map(x => x.item));
  };

  return (
    <div className="companies-stock-data">
      <div className="search-wrapper">
        <input
          type="search"
          name="search"
          value={searchTerm}
          onChange={e => {
            if (e.target.value === '') {
              setResult(companies);
            }
            setSearchTerm(e.target.value);
          }}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              search();
            }
          }}
          id="search"
          className="search-input"
          placeholder="Search"
        />
      </div>
      {companies.length === 0 && (
        <ClipLoader
          color="#ffffff"
          loading={companies.length === 0}
          cssOverride={{
            display: "block",
            marginTop: "25%",
            borderColor: "#771CD1",
          }}
          size={100}
          aria-label="Loading Spinner"
          data-testid="loader"
        />)  
      }
      <div className="companies-list">{allCompany(result)}</div>
    </div>
  );
};

export default ListCompanies;
