import './HomeHeader.scss';
import { FiLogIn } from "react-icons/fi";
import React from 'react';

// import {useDispatch} from 'react-redux';
import Cookies from 'js-cookie';

import excel24_logo from '../../../assets/excel@2024.svg';
import logo2 from '../../../assets/play2022.png';
import { AERoot } from '../../../config/api';

interface Props {
  userPic?: string;
  username?: string;
  isAuth?: boolean;
}

const HomeHeader: React.FunctionComponent<Props> = (props) => {
  const { userPic, username, isAuth } = props;
  // const dispatch = useDispatch();

  return (
    <div className="big-header-bg">
      <div className="excel-logo-container">
        <a href="http://excelmec.org/#/">
          <img className="excel-logo" src={excel24_logo} alt="excel logo" />
        </a>
      </div>

      {username === null ? (
        <div className="logo-container"></div>
      ) : (
        <div className="logo-container">
          <img src={logo2} alt="logo" className="logo" />
        </div>
      )}
      <div className="userDetails">
        {username === null ? (
          <div className="excel-logo-container-go">
            <a href="http://excelmec.org/#/">
              <img className="excel-logo" src={excel24_logo} alt="excel logo" />
            </a>
          </div>
        ) : isAuth ? (
          <div className={"dropdown"}>
            <div
              className="btn border-0 "
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className={"pt-1 pr-1 pl-1 d-block"}>
                <img className="propic" alt="profile pic" src={userPic} />
                <span
                  style={{ marginRight: "25px" }}
                  className={"d-none d-md-inline-block pr-1"}
                >
                  {username}
                </span>
              </span>
            </div>
            <div
              className="dropdown-menu dropdown-menu-right"
              aria-labelledby="dropdownMenuButton"
            >
              {isAuth ? (
                <a
                  onClick={() => {
                    Cookies.remove("sessionid");
                    Cookies.remove("jwt_token");
                    window.localStorage.removeItem("refreshToken");
                    window.location.replace(
                      `${AERoot}auth/logout?redirect_to=${window.location.href}`
                    );
                  }}
                  className="dropdown-item"
                >
                  Logout
                </a>
              ) : (
                <a
                  onClick={() => {
                    window.location.replace(
                      `${AERoot}auth/login?redirect_to=${window.location.href}`
                    );
                  }}
                  className="dropdown-item"
                >
                  Login
                </a>
              )}
            </div>
          </div>
        ) : (
          <button
            type="button"
            className="button"
            aria-haspopup="true"
            aria-expanded="false"
            onClick={() => {
              window.location.replace(
                `${AERoot}auth/login?redirect_to=${window.location.href}`
              );
            }}
          >
            <FiLogIn/> Login
          </button>
        )}
      </div>
      {/* ) : ( */}
      {/* <div className="userDetails" /> */}

      {/* )} */}
    </div>
  );
};

export default HomeHeader;
