import React from 'react';
import './DalalbullRules.scss';

const DalalbullRules = () => {
  return (
    <div className="rules container pb-5">
      <h2 className="font-weight-bold pl-2 pt-5 ">Rules and Event Format</h2>
      <ol>
        <li>
          Basically 4 different types of transactions can be performed :-
          <ul>
            <li>Buy</li>
            <li>Sell</li>
            <li>Short Sell</li>
            <li>Short Cover</li>
          </ul>
        </li>
        <li>Trading can be done on 30 companies from the NSE.</li>
        <li>
          The game will follow actual market timings (9:15 AM to 3:30 PM
          IST).Trading can be done only on weekdays(Monday To Friday).All stock
          data is real-time fetched from published sources which are provided to
          all participants.
        </li>
        <li>
          The following terminologies related to Dalalbull are used on a
          frequent basis:-
          <ul>
            <li>
              Stock Info: DALALBULL provides stock information for 30 companies
              from NSE and you are allowed to trade with these
              companies only. The stock table displayed contains the following
              information.
            </li>
            <li>
              Open: Price level at which the pre open market traded the shares.
            </li>
            <li>High: Highest price during the day.</li>
            <li>Low: Lowest price during the day.</li>
            <li>Current price: Shows the last traded price for the share.</li>
            <li>
              Change: Difference of current price with respect to previous
              close.
            </li>
            <li>
              % Change: Percentage difference of current price with respect to
              previous close.
            </li>
            <li>
              Total trade Value: Total value of shares traded during the day.
            </li>
            <li>
              Cash Balance: The sum of Cash In Hand and the Margin you deposit
              after Short Selling.
            </li>
            <li>
              Cash In Hand: The maximum amount with which you can trade at any
              point of time. Initially each player is provided with Rs 50,000 of
              virtual money to start the game.
            </li>
            <li>
              Margin: While Short Selling half of the trade value is deducted
              from cash in hand and deposited as margin. This is to ensure that
              the user is able to Short Cover the same even if price rises by
              50%.
            </li>
            <li>
              Net Worth: The total of cash balance, margin and worth of all the stocks
              in procession and this decides your rank.
            </li>
            <li>
              No. of Transaction: The total number of transactions carried out
              by the broker on your behalf. Brokerage charged depends on the
              number of transactions carried out. Brokerage: Here at DalalBull
              for first 100 transactions will be charged 0.5% of transactions
              value as brokerage, next 900 transactions will attract 1%
              brokerage and beyond which 1.5% will be the brokerage.
            </li>
          </ul>
        </li>
       <li>
          A live leaderboard will be maintained so that the participant can
          check his/her ranking.
        </li>
        <li>
          The admins have the ability to disqualify any players for fraudulent
          behavior.
        </li>
        <li>
          The last day of trading will be Jan 24th 2025(Wednesday).
        </li>
      </ol>
    </div>
  );
};

export default DalalbullRules;
