import "./Home.scss";

import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import Carousel from "react-spring-3d-carousel";

import arrowLeft from "../../assets/arrowcircleleft.png";
import bgImg from "../../assets/bg/bg.jpg";
import HomeHeader from "../../components/common/HomeHeader/HomeHeader";
import Spinner from "../../components/common/Spinner/Spinner";
import Games from "../../components/Home/Games/Games";
import { KryptosRoot, MiniRoot, SpellRoot } from "../../config/api";
import * as http from "../../config/http";
import GameCard from "../../components/Home/Games/GameCard";

import KryptosBg from "../../assets/bg/kryptos.jpg";
import NovadashBg from "../../assets/bg/novadash.webp";
import DalalbullBg from "../../assets/bg/dalalbull.png";

export const fetchUserRank = async (refresh_token: string) => {
  await http.generateJwtToken(refresh_token);
  if (refresh_token) {
    return http.get(`${KryptosRoot}/api/getrank`);
  }
};

const Home = () => {
  const [kryptosRank, setKryptosRank] = useState(0);
  const [dalalbullRank, setDalalbullRank] = useState(0);
  const [circuimstanceRank, setCircuimstanRank] = useState(0);
  const [userPic, setUserPic] = useState("");
  const [username, setUserName] = useState("");
  const [auth, setAuth] = useState(false);
  const [miniUser, setminiUser] = useState({
    ranks: {
      novadash: 0,
    },
  });
  const [device, setDevice] = useState("desktop");
  const token = useSelector((store: any) => store.auth.token);
  const [load, setLoad] = useState(true);
  const [goToSlide, setGoToSlide] = useState(0);
  const [xDown, setXDown] = useState(null);
  const [yDown, setYDown] = useState(null);
  const [wordleRank, setWordleRank] = useState(0);

  async function getNovaDashRank() {
    try {
      const novaDashBackendBaseUrl =
        process.env.REACT_APP_NOVADASH_BACKEND_BASE_URL;
      if (novaDashBackendBaseUrl && token) {
        const response = await http.get(`${novaDashBackendBaseUrl}/doodle/score`);
        setminiUser({ ranks: { novadash: response.rank } });
        // console.log(response);
      } else {
        // console.log('Doodle rank not fetched');
      }
    } catch (error) {
      console.log("Error fetching user doodle rank", error);
      setminiUser({ ranks: { novadash: undefined } });
    }
  }

  async function getDalalBullRank() {
    try {
      const dalalbullBackendBaseUrl =
        process.env.REACT_APP_DALALBULL_BACKEND_BASE_URL;
      if (dalalbullBackendBaseUrl && token) {
        const response = await http.get(`${dalalbullBackendBaseUrl}/dalalbull/api/getrank/`);
        let rank = response?.rank;
        if (rank < 0) rank=0;
        setDalalbullRank(rank);
      } else {
        setDalalbullRank(0);
      }
    } catch (error) {
      console.log("Error fetching user dalalbull rank", error);
      setDalalbullRank(undefined);
    }
  }

  useEffect(() => {
    http.getUser(token).then((data) => {
      // console.log(data)

      if (!(Object.getOwnPropertyNames(data).length === 0)) {
        setUserPic(data.picture);
        setUserName(data.name);

        // This was for Exagon
        // http.getMini(`${MiniRoot}/user/${data.email}`).then((d) => {
        //   if (d.length == 0) {
        //     http.postMini(`${MiniRoot}/create_user?email=${data.email}&name=${data.name}`, '')
        //       .then(da => setminiUser({ scores: da.user.scores }))
        //       .catch(err => console.log(err))
        //   }
        //   else {
        //     setminiUser(d[0])
        //   }

        // }).catch(err => console.log(err))

        // http.getSpellRank(`${SpellRoot}/getRankbyEmail`, data.email).then((s) => {
        //   if('rank' in s){
        //     setSpellRank(s.rank)
        //   }
        // })
        setAuth(true);

        try {
          fetchUserRank(token).then((data) => {
            // console.log(data)
            if (typeof data === "number" && data >= 0 && data != undefined) {
              setKryptosRank(data);
            }
          });
        } catch (error) {
          //console.log("Error fetching user kryptos rank", error);
          setKryptosRank(0);
        }

        getNovaDashRank();
        getDalalBullRank();
      }
    });
  }, [token]);

  useEffect(() => {
    setTimeout(() => {
      setLoad(false);
    }, 2000);

    if (window.innerWidth < 1000) {
      setDevice("mobile");
    }
  }, []);

  useEffect(() => {
    setInterval(() => {
      setGoToSlide((x) => x + 1);
    }, 8000);
  }, []);

  const CarouselCard = ({ details }) => {
    let rankStr: string = "";
    if (typeof details.rank === "number") {
      rankStr = details.rank.toString();
    } else if (typeof details.rank === "string") {
      rankStr = details.rank;
    } else {
      rankStr = "-";
    }

    return (
      <div
        className="carouselCard"
        onClick={() => {
          window.location.href = details.href;
        }}
      >
        <div className="rankBox">
          <div className="title">
            <p style={{ fontSize: "15px" }}>{rankStr}</p>
            <p>{"Rank"}</p>
          </div>
        </div>
        <div className="icon">
          <img src={details.icon} width="100px" />
        </div>
        <div className="gameName">{details.name}</div>
        <div className="playButton">Play</div>
      </div>
    );
  };

  const slides = [
    {
      key: 1,
      content: (
        <GameCard
          title="KRYPTOS"
          rank={kryptosRank}
          href="/kryptos"
          imageUrl={KryptosBg}
        />
      ),
    },
    {
      key: 2,
      content: (
        <GameCard
          title="NOVA DASH"
          rank={miniUser?.ranks?.novadash}
          href="/novadash"
          imageUrl={NovadashBg}
        />
      ),
    },
    {
      key: 3,
      content: (
        <GameCard
          title="DALALBULL"
          rank={dalalbullRank}
          href="/dalalbull"
          imageUrl={DalalbullBg}
          live={true}
        />
      ),
    },
  ];

  const getTouches = (evt) => {
    return evt.touches || evt.originalEvent.touches;
  };

  const handleTouchStart = (e) => {
    //console.log("Touch start");
    const firstTouch = getTouches(e)[0];
    setXDown(firstTouch.clientX);
    setYDown(firstTouch.clientY);
  };

  const handleTouchMove = (evt) => {
    evt.preventDefault();
    //console.log("Touch end");
    if (!xDown && !yDown) {
      return;
    }

    let xUp = evt.touches[0].clientX;
    let yUp = evt.touches[0].clientY;

    let xDiff = xDown - xUp;
    let yDiff = yDown - yUp;
    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      if (xDiff > 0) {
        setGoToSlide((x) => x + 1);
        setXDown(null);
        setYDown(null);
      } else {
        setGoToSlide((x) => x - 1);
        setXDown(null);
        setYDown(null);
      }
    }
  };

  // useEffect(() => {
  //   console.log(miniUser)
  // },[miniUser])
  if (load) {
    return <Spinner />;
  } else {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          overflowX: "scroll",
          overflowY: "scroll",
        }}
        className="main"
      >
        <img src={bgImg} className="bg" alt="bg" />
        <div className="">
          <HomeHeader username={username} userPic={userPic} isAuth={auth} />
        </div>
        <div className="container">
          {device === "desktop" ? (
            <div className="events">
              <div className="flexbox">
                <div className="excel-play">
                  <h1 className="heading">Excel Play</h1>
                  <p className="subheading">ENTER THE NEXT LEVEL</p>
                </div>
                <div className="game-grid">
                  <GameCard
                    title="KRYPTOS"
                    rank={kryptosRank}
                    href="/kryptos"
                    imageUrl={KryptosBg}
                  />
                  <GameCard
                    title="NOVA DASH"
                    rank={miniUser?.ranks?.novadash}
                    href="/novadash"
                    imageUrl={NovadashBg}
                  />
                  <GameCard
                    title="DALALBULL"
                    rank={dalalbullRank}
                    href="/dalalbull"
                    imageUrl={DalalbullBg}
                    live={true}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                style={{ height: "60vh", width: "90vw" }}
              >
                <Carousel
                  goToSlide={goToSlide}
                  showNavigation={false}
                  slides={slides}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-around",
                  marginTop: "3rem",
                }}
              >
                <div>
                  <img
                    onClick={() => setGoToSlide((i) => i - 1)}
                    src={arrowLeft}
                    className={"left"}
                  />
                </div>
                <div>
                  <img
                    onClick={() => setGoToSlide((i) => i + 1)}
                    src={arrowLeft}
                    className={"right"}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
};

export default Home;
