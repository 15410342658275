import React from 'react';
import './NovaDash.css';

export default function NovaDash() {

    const refreshToken = localStorage.getItem('refreshToken');
    let frameUrl = new URL(process.env.REACT_APP_NOVADASH_URL || '/');

    if (refreshToken) {
        frameUrl.searchParams.set('refreshToken', refreshToken);
    }

	return (
		<div className='doodle-wrapper'>
			<iframe
				className='doodle-iframe'
				src={frameUrl.toString()}
				title='Excel PLAY | NovaDash'
			/>
		</div>
	);
}
