import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AERoot } from '../../config/api';
import configs from '../../config/auth_config';
const config = configs();

const Authorisation = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		// function receiveMessage(event: any) {
		//   console.log(event.data)
		//   if (typeof event.data == 'string' && event.data.length !== 0) {
		//     dispatch({
		//       type: 'AUTH_SUCCESS',
		//       payload: { token: event.data },
		//     });
		//   } else if (event.data === null) {
		//     dispatch({
		//       type: 'AUTH_FAIL',
		//       payload: { token: null },
		//     });
		//   };
		// };
		// window.addEventListener("message", receiveMessage);
		let tok = window.localStorage.getItem('refreshToken');
		// console.log(tok)
		if (tok !== null) {
			dispatch({
				type: 'AUTH_SUCCESS',
				payload: { token: tok },
			});
		} else {
			const rt = new URL(window.location.href).searchParams.get(
				'refreshToken'
			);
			//console.log("refresh token:", rt);
			if (typeof rt == 'string' && rt.length !== 0) {
				window.localStorage.setItem('refreshToken', rt);
				dispatch({
					type: 'AUTH_SUCCESS',
					payload: { token: rt },
				});
				window.history.replaceState(
					{},
					document.title,
					window.location.href.split('?')[0]
				);
			} else if (rt === null) {
				dispatch({
					type: 'AUTH_FAIL',
					payload: { token: null },
				});
			}
		}
	}, []);

	return (
		// <iframe
		//   title="authorize"
		//   style={{ display: "none" }}
		//   id="content"
		//   src={`${AERoot}auth/authorize`}
		//   width="0px"
		//   height="0px"
		// >
		// </iframe>
		<></>
	);
};

export default Authorisation;
