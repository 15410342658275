import "./Games.css";

import React from "react";
interface GameCardProps {
  title: string;
  rank: number;
  href: string;
  imageUrl: string;
  live?: boolean;
}

const GameCard = ({ imageUrl, title, rank, href, live=true }: GameCardProps) => {
  return (
    <a href={href} style={{ textDecoration: "none" }}>
      <div className="card-container">
        <div className="card-border"></div>

        {/* Inner Card Content */}
        <div className="card-content">
          {/* Game Image Area */}
          <div className="image-area">
            <img src={imageUrl} alt={title} className="game-image" />
            <div className="image-gradient"></div>

            {/* Cyber Corner Decorations */}
            <div className="corner-decoration top-left"></div>
            <div className="corner-decoration top-right"></div>

            {/* Scanning Line Effect */}
            <div className="scanning-line"></div>
          </div>

          {/* Content Area */}
          <div className="content-area">
            {/* Title with Tech Effect */}
            <div className="title-section">
              <h3 className="game-title">{title}</h3>

              {/* Rank Indicators */}

              <div className="rank-indicators">
                <div className="rank-box">
                  <div className="rank-decoration"></div>
                  <div className="rank-background"></div>
                  <span className="rank-number">{rank !== 0 && rank}</span>
                </div>
                <div className="rank-text-container">
                  <p className="rank-text">{rank === 0 ? "UNRANKED" : "RANK" }</p>
                  <div className="rank-line"></div>
                </div>
              </div>

            </div>

            {/* Futuristic Play Button */}
            <button className="play-button" disabled={!live}>
              <span>{live ? "PLAY" : "COMING SOON"}</span>
            </button>
          </div>
        </div>

        {/* Tech Grid Overlay */}
        <div className="tech-grid-overlay"></div>
      </div>
    </a>
  );
};

export default GameCard;
