
export const WSRoot =
  window.location.host === "play.excelmec.org"
    ? "wss://api.play.excelmec.org/"
    : "wss://dalalbull-api.excelmec.org/";

export const ApiRoot =
  window.location.host === "play.excelmec.org"
    ? "https://dalalbull-api.excelmec.org/"
    : "https://dalalbull-api.excelmec.org/";

export const KryptosRoot = process.env.REACT_APP_KRYPTOS_BACKEND_BASE;

export const MiniRoot = "https://leaderboard-production-a241.up.railway.app"

export const AuthRoot =
  window.location.host === "play.excelmec.org"
    ? "https://accounts-api.excelmec.org/api"
    : "https://accounts-api.excelmec.org/api";

export const AERoot = 
  window.location.host === "play.excelmec.org"
    ? "https://auth.excelmec.org/"
    : "https://auth.excelmec.org/"

 export const SpellRoot = "https://spelling-bee-five.vercel.app/api"
