import Cookies from 'js-cookie';
import {  AuthRoot } from './api';
import { dalalbullLogin } from '../pages/DalalBull/DalalbullComponents/apicalls/apicalls';

export const post = (url:string, data: any) => {
  let token= Cookies.get('jwt_token')
  return fetch(url, {
    method: 'POST',
    body: data,
    headers: {"Authorization" : 
  `Bearer ${token}`,
  'Accept' : 'application/json',
  'Content-Type': 'application/json'
    }
  })
    .then(res => res.json())
    .catch(err => err);
};

export const getMini = (url:string) => {
  return fetch(url).then(res => res.json())
  .catch(err => console.log(err))
}

export const getSpellRank = (url: string, data: object) =>{
  let d = {
    email: data
  }
  return fetch(url, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json"
    },
    body : JSON.stringify(d)
  }).then((res) => res.json())
}

export const postMini = async (url:string, score: any) => {
  let tok = window.localStorage.getItem('refreshToken')
  await generateJwtToken(tok);
  const token = Cookies.get('jwt_token') || '';
  let data = {
    new_score: score
  }
  return fetch(url, {
    method: 'POST',
    headers: {
      "Authorization" : `Bearer ${token}`,
      "Content-Type": "application/json"
    },
    body : JSON.stringify(data)
  }).then(res => res.json())
  .catch(err => console.log(err))
}

export const get = (url:string) => {
  let token= Cookies.get('jwt_token')
  return fetch
  (url, 
    {   
    headers: {"Authorization" : 
  `Bearer ${token}`,
  'Accept' : 'application/json',
  'Content-Type': 'application/json'
    } 
})
    .then(res => res.json())
    .catch(err => console.log(err));
};
export const getWithCred = (url:string) => {
  return fetch(url, {
    credentials:'include'
  })
    .then(res => res.json())
    .catch(err => console.log(err));
};
export const postWithCred = (url:string, data: any) => {
  return fetch(url, {
    method: 'POST',
    credentials:'include', 
    body: data
  })
    .then(res => res.json())
    .catch(err => err);
};
export const generateJwtToken = async(token:any) => {
  // console.log(token)
  const tok = Cookies.get('jwt_token');
  if((!tok) || tok == undefined || tok == 'undefined'){
    var refresh={"refreshToken" :token};
    if(token){
      let result=await post(`${AuthRoot}/Auth/refresh`,JSON.stringify(refresh));
      //console.log("Generated JWTToken:",result.accessToken)
      Cookies.set('jwt_token',result.accessToken, {expires: (1 / 1440)*5 });
      window.location.reload();
    }
    // await dalalbullLogin(result.accessToken);
  }
}

export const getUser = async(token: any) => {
  await generateJwtToken(token);
  const tok = Cookies.get('jwt_token') || '';
  //console.log(tok)
  // console.log(tok)
  if(tok && tok != undefined && tok != 'undefined'){
    // console.log("User token", tok)
    var base64Url = tok.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    //console.log("User token:", jsonPayload)
    return JSON.parse(jsonPayload);
  }else{
    //console.log("User token not found")
    return {};
  }
}
